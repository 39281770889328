import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CustomersPage = () => (
  <Layout>
    <SEO title="Some of our customers" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <article className="slim">
        <h1>Some of our customers</h1>
        <p>nVotes is a private company and has no political affiliation of any sort.</p>
        <h2>Association of Surgeons of Spain</h2>
        <div>
          <div>
            <p>nVotes was used in the Board of Directors election</p>
          </div>
        </div>
        <h2><strong>ASTIC</strong></h2>
        <div>
          <div>
            <p>National Public Administrations Systems &amp; Technology Professionals Association. Those responsible of technology in the public sector have chosen our solution to manage their elections.</p>
          </div>
        </div>
        <h2><strong>Barcelona Provincial Council</strong></h2>
        <div>
          <div>
            <p>Barcelona Provincial Council is a local government institution that promotes the progress and welfare of the citizens in its territory and provides services to 311 municipalities</p>
          </div>
        </div>
        <h2><strong>Engineers Professional Association of Barcelona</strong></h2>
        <div>
          <div>
            <p>Internal election with a 680% participation increase</p>
          </div>
        </div>
        <h2><strong>Eurochild</strong></h2>
        <div>
          <div>
            <p>Eurochild Foundation&nbsp;is a network of organisations and individuals working in and across Europe to promote the rights and well-being of children and young people.</p>
          </div>
        </div>
        <h2><strong>Lugo City Hall</strong></h2>
        <div>
          <div>
            <p>Lugo is a city in northwestern Spain with a population of around 100.000 that used our voting system to run a participatory budgeting process in 2015.</p>
          </div>
        </div>
        <h2><strong>Madrid City Hall</strong></h2>
        <div>
          <div>
            <p>In February 2017 nVotes was used to run an election with more than 2,7M eligible voters.</p>
          </div>
        </div>
        <h2><strong>Madrid Police Union</strong></h2>
        <div>
          <div>
            <p>Madrid Police Union used nVotes software to carry out a consultation to all its members in 2015.</p>
          </div>
        </div>
        <h2><strong>Oak House School</strong></h2>
        <div>
          <div>
            <p>Oak House School is a private non-profit making Foundation that runs a school in Spain. They ran an election to select the memberse of the Board of Trustees in 2016.</p>
          </div>
        </div>
        <h2><strong>Som Energia</strong></h2>
        <div>
          <div>
            <p>Som Energia is a renewable energies cooperative that has used nVotes to run several elections with up to 35.000+ eligible&nbsp;voters.</p>
          </div>
        </div>
        <h2><strong>SV Norway</strong></h2>
        <div>
          <div>
            <p>Political party in Norway founded in 1975 using nVotes for internal elections</p>
          </div>
        </div>
        <h2><strong>UNED</strong></h2>
        <div>
          <div>
            <p>With over 260,000 students, the National University of Distance Education&nbsp;(UNED) is the largest university in Spain and the second largest in Europe.</p>
          </div>
        </div>
      </article>
  </Layout>
)

export default CustomersPage
